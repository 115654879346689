exports.components = {
  "component---src-page-templates-artikkel-tsx": () => import("./../../../src/pageTemplates/artikkel.tsx" /* webpackChunkName: "component---src-page-templates-artikkel-tsx" */),
  "component---src-page-templates-koolitus-tsx": () => import("./../../../src/pageTemplates/koolitus.tsx" /* webpackChunkName: "component---src-page-templates-koolitus-tsx" */),
  "component---src-page-templates-koolitused-tsx": () => import("./../../../src/pageTemplates/koolitused.tsx" /* webpackChunkName: "component---src-page-templates-koolitused-tsx" */),
  "component---src-page-templates-lugemist-tsx": () => import("./../../../src/pageTemplates/lugemist.tsx" /* webpackChunkName: "component---src-page-templates-lugemist-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-noustamine-tsx": () => import("./../../../src/pages/noustamine.tsx" /* webpackChunkName: "component---src-pages-noustamine-tsx" */),
  "component---src-pages-pealeht-tsx": () => import("./../../../src/pages/pealeht.tsx" /* webpackChunkName: "component---src-pages-pealeht-tsx" */),
  "component---src-pages-teraapia-tsx": () => import("./../../../src/pages/teraapia.tsx" /* webpackChunkName: "component---src-pages-teraapia-tsx" */)
}

